import React from 'react';
import {useFormValidation} from '../../../utils/Validator'
import { AddForm, EditForm } from '../../molecules';
import { seasons } from '../../../constants/constants';
import { ModalContainer } from '../../atoms';
import { postIngredients, editIngredients } from '../../../store/api';
import { useDispatch, useSelector } from 'react-redux'
import { filtrSearchArr } from '../../../utils/utils.js'

export function AddEditIngredient(props) {
  const dispatch = useDispatch();
  const { lib, status } = useSelector(state => state.lib)
  //const { statusPost, errorPost } = useSelector(state => state.ingredients)
  const [filtrMassiv, setFiltrMassiv] = React.useState([]);
  const [choiceIngredient, setChoiceIngredient] = React.useState([])
  const [ingredient, setIngredient] = React.useState('') 
  const [hidden, sethidden] = React.useState(true)
  const [fields, setFields] = React.useState([])
  const [vitam, setVitam] = React.useState({})
  const editCardData = props.card;
  const copyCard = props.copyCard
  const { 
    values, 
    handleChange,
    resetForm,
    setValues,
    nameError
    } = useFormValidation({trait: ''});
  const submitDisabled = values.name === undefined || values.name === '' || values.category === '' || values.category === 'none' || values.category === undefined || values.calories === '' ||
    values.calories === undefined || values.protein === '' || values.protein === undefined || values.fats === '' ||
    values.fats === undefined || values.carb === '' || values.carb === undefined ||
    values.price === undefined || values.price === '' ||
    values.weightprice === undefined || values.weightprice === ''; 
    console.log(fields)
    //Обнуление формы и наследование данных с сервера при редактировании
  
    React.useEffect(()=> {
    resetForm() 
    if(editCardData || copyCard) { 
      const measure = measureRatio(editCardData.measure)
      setVitam(editCardData.minerals === undefined ? {} : editCardData.minerals) 
      setValues({
        name: editCardData.name,
        nameEn: editCardData.nameEn,
        trait: editCardData.trait,
        price: editCardData.price && editCardData.price.rate,
        measureprice: editCardData.price && editCardData.price.measureprice,
        weightprice: editCardData.price && editCardData.price.weightprice,
        category: editCardData.category,
        calories: editCardData.calories.calories,
        protein: editCardData.calories.protein, 
        fats: editCardData.calories.fats,
        carb: editCardData.calories.carb,
        digest: editCardData.calories.digest,
        desc: editCardData.desc,
        author: editCardData.link,
        id: editCardData._id,
        nameRu: editCardData.name,
        brands: editCardData.brands,
        season: editCardData.season,
        mainMeasure: editCardData.mainMeasure,
        cooking: editCardData.cooking,
        frying: editCardData.frying,
        purified: editCardData.purified,
        packing: [],
        ...measure,
        ...editCardData.minerals,
      })
      setFields(editCardData.fields === null ? [] : editCardData.fields)
      setChoiceIngredient(editCardData.brands)
     // setMeasure(editCardData.measure)
      //setVitamins(editCardData.vitamins)
    }
  }, [props.isOpen, props.card]) 

  console.log(values)
  // Заполнение меры из базы при редактировании
  /*function measureRatio(measure, array) {
    const arr ={}
    if(measure !== null) {
      array.find( i => measure.some(k=> {
        if(i._id === k.id) {
          arr[i.nameEn] = k.ratio
        }
      }))
    }
    return arr
  }*/
  function measureRatio(measure) {
    const arr ={}
    if(measure !== null) {
    measure.map((i)=> {
      arr[i.nameEn] = i.ratio
      }) 
    }
    return arr
  }
 
  function hanleChangeRatio(obj, array) {
    console.log(obj, array)
    let arr=[]
    Object.entries(obj).map(([key, value]) => {
      array.map((item) => {
        if( key=== item.nameEn && value!== ''){
          arr.push({
            nameRu: item.nameRu,
            nameEn: item.nameEn,
            ratio: value
          }) 
        }
      })
    })
    return arr
  }

  
  function hanleChangeVit(obj, array) {
    console.log(obj, array)
    let arr=[]
    Object.entries(obj).map(([key, value]) => {
      array.map((item) => {
        if( key=== item.nameEn && value!== ''){
          arr.push({
            nameRu: item.nameRu,
            nameEn: item.nameEn,
            ratio: value
          }) 
        }
      })
    })
    return arr
  }

 //Изменение инпута ингредиентов
 function handleChangeInput(e) {
  setIngredient(e.target.value)
  handleFiltrClick(e.target.value, e.target.name)
  //errorMessage(e)
  sethidden(true)
}
 //Поиск в инпуте по ингридиенту
 function handleFiltrClick(keyword, name) {
  if(keyword!=='') {
  const newMassiv = filtrSearchArr(lib.brands, keyword, name)
  setFiltrMassiv(newMassiv)
  } else {
    setFiltrMassiv([])
  }
}

 //Подстановка автоматически значения в инпут из выбранного
 function handleAutoText(text) { 
  setChoiceIngredient([...choiceIngredient, text])
  setIngredient('')
  sethidden(false)
} 

  // Изменение фасовки для каждго бренда
  function handleClickPacking(packing, item) {
    const type = [...choiceIngredient]
    const pack = type.map((c) => {
      const news = {...c};
      if(c._id === item._id) {
        news.packing = c.packing.map(k=> k.weight === packing.weight ? packing : k)
      } 
      return news
    })
    setChoiceIngredient(pack)    
  }

 //Удаление из списка брендов 
  function handleDeleteChoice(nameRu) {
    setChoiceIngredient((cards)=> cards.filter((c) => c.nameRu !== nameRu));
  }

  //функция добавления 1 ингридиентав в БД
  function addIngredients(arr) {
    const newArr = {
      name: arr.values.name,
      nameRu: arr.values.name,
      trait : arr.values.trait,
      nameEn: arr.values.nameEn,
      calories: {
        protein: arr.values.protein,
        fats: arr.values.fats,
        carb: arr.values.carb,
        calories: arr.values.calories,
        digest: arr.values.digest,
      },
      fields: fields,
      brands: choiceIngredient,
      measure: arr.measures,
      vitamins: [],
      minerals: vitam,
      season: arr.values.season,
      accusativeCase: '',
      genitiveCase: '',
      instrumentalCase: '',
      category: arr.values.category,
      desc: arr.values.desc,
      image: [],
      choisMeasure: arr.values.mainMeasure,
      mainMeasure: arr.values.mainMeasure,
      weight: 0,
      price: {
        rate: arr.values.price,
        weightprice: arr.values.weightprice,
        measureprice: arr.values.mainMeasure,
      },
      cooking: arr.values.cooking,
      frying: arr.values.frying,
      purified: arr.values.purified,
      packing: [],
      link: arr.values.author,
    }
   
    dispatch(postIngredients(newArr))
  }
 
  //функция изменения 1 ингридиентав в БД
  function editIngredient(arr) {
    dispatch(editIngredients(arr))
  }
 
  //отправка данных при редактировании
  function handleEditSubmit(event) {
    event.preventDefault();
    const measures = hanleChangeRatio(values, lib.measure) 
    const minerals = vitam
    if(!copyCard) {
    editIngredient({values, fields: fields, measures, vitamins: [], minerals, choiceIngredient, id: editCardData._id })
    } else {
      addIngredients({values, measures, vitamins: [], minerals})
    }
    props.onClose()
  }
  
  //отправка данных при добавлении
  function handleAddSubmit(event, arr) {
    event.preventDefault();
    const measures = hanleChangeRatio(values, lib.measure)
    const minerals = vitam
    addIngredients({values, measures, vitamins: [], minerals})
    props.onClose() 
  }

  function  handleExcept(item, values) { 
    if (values && !fields.includes(item._id)) {
      setFields([...fields, item._id])
    } else {
      setFields(fields.filter(field => field !== item._id))
    }
  }
  
  console.log(fields)

  function handleChangeMinerals(e) {
    const { name, value } = e.target;
    console.log(name, value)
    setVitam(prevState => ({
    ...prevState,
    [name]: value
    }));
  }

  return (
    
    <>
    { status === 'resolved' &&
      <>
    <ModalContainer 
        isOpen={props.isOpen}
        head='Добавить ингредиент'
        title='add-ingredient'
        onClose={props.onClose}
    > 
      <AddForm 
      onSubmit={handleAddSubmit}
      handleDelete={handleDeleteChoice}
      onChange={handleChange} 
      name={values.name}
      seasons = {seasons}
      price={values.price} 
      handleExcept={handleExcept}
      mainMeasure={values.mainMeasure} 
      weightprice = {values.weightprice}
      category={values.category}
      calories={values.calories}
      protein={values.protein}
      fats={values.fats}
      carb={values.carb}
      digest={values.digest}
      title='Добавить ингредиент'
      nameError={nameError}
      vitam={vitam}
      submitDisabled={submitDisabled}
      filtrMassiv={filtrMassiv}
      handleAutoText={handleAutoText}
      choiceIngredient={choiceIngredient}
      handleClickPacking={handleClickPacking}
      hidden={hidden}
      handleChangeInput={handleChangeInput}
      handleChangeMinerals={handleChangeMinerals}
      ingredient={ingredient}
      fields={fields}
      lib={lib}
      values={values}
    />
    </ModalContainer>
    <ModalContainer 
        isOpen={props.isOpenEdit}
        head='Редактировать ингредиент'
        title='edit-ingredient'
        onClose={props.onClose}
    >
    <EditForm
      lib={lib}
      handleDelete={handleDeleteChoice}
      ingredient={ingredient}
      onSubmit={handleEditSubmit}
      values={values}
      vitam={vitam}
      fields={fields}
      seasons = {seasons}
      onChange={handleChange}
      filtrMassiv={filtrMassiv}
      mainMeasure={values.mainMeasure}
      handleClickPacking={handleClickPacking}
      name={values.name}
      price={values.price} 
      weightprice = {values.weightprice}
      category={values.category}
      cooking={values.cooking}
      calories={values.calories}
      protein={values.protein}
      fats={values.fats}
      carb={values.carb}
      digest={values.digest}
      isOpen={props.isOpenEdit}
      measure={editCardData && editCardData.measure}
      editCardData={editCardData}
      title='Редактировать ингредиент'
      hidden={hidden}
      nameError={nameError}
      handleExcept={handleExcept}
      submitDisabled={submitDisabled}
      handleAutoText={handleAutoText}
      choiceIngredient={choiceIngredient}
      vitamins={lib.vitamins}
      handleChangeInput={handleChangeInput}
      handleChangeMinerals={handleChangeMinerals}
    />
    </ModalContainer>
    </>
}
    </>
  )
}