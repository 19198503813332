import React from 'react';

export function InputMeasure(props) {
   const item = props.item
  return (
    <label className={`form__label form__label_type_${props.types}`} htmlFor={props.name}> {props.nameru} {item!== undefined && props.item.measure !== undefined && props.item.measure}
    <input 
      type={props.type}
      autoComplete="off" 
      value={props.value || ''} 
      step='any'
      min = '0'
      lang="en"
      onChange={props.onChange} 
      placeholder={props.placeholder}
      className = {`form__input form__input_type_${props.name}`} 
      name={props.name} 
      id={props.id} 
    />
    {props.nameError && <span className="email-error form__input_error">{props.nameError}</span>}
     </label>
  )        
}
  