import React from 'react';
import './IngredientsTable.css';
import { deleteIngredients, editIngredients } from '../../../store/api.js'
import {useDispatch, useSelector} from 'react-redux'
import { PriceBlock } from '../../molecules';
import { Except, InputMeasure } from '../../atoms';
import {useFormValidation} from '../../../utils/Validator'  

const MeasureData = (props) => {
  const item = props.item
 
  return (
    <label className={`form__label form__label_type_${props.types}`} htmlFor={props.nameEn}> {props.nameRu}
    <input 
      type={props.type}
      autoComplete="off" 
      value={props.value} 
      step='any'
      onChange={props.onChange} 
      placeholder={props.placeholder}
      className = {`form__input form__input_type_${props.name}`} 
      name={props.name} 
      id={props.id} 
    />
    {props.nameError && <span className="email-error form__input_error">{props.nameError}</span>}
     </label>
  )
}
export function IngredientsTable(props) {
  const { 
    values, 
    handleChange,
    setValues,
    } = useFormValidation({});
  const ingredient = props.ingredient
  const dispatch = useDispatch()
  const { lib, status } = useSelector(state => state.lib)
  let id = ingredient._id
  const [measure, setMeasure] = React.useState() 
  const [fields, setFields] = React.useState([])

  React.useEffect(() => { 
    const measure = measureRatio(ingredient.measure)
    setValues({
      name: ingredient.name,
      price: ingredient.price && ingredient.price.rate,
      measureprice: ingredient.price && ingredient.price.measureprice,
      weightprice: ingredient.price && ingredient.price.weightprice,
      category: ingredient.category,
      trait: ingredient.trait,
      frying: ingredient.frying,
      calories: ingredient.calories.calories,
      protein: ingredient.calories.protein,
      fats: ingredient.calories.fats,
      carb: ingredient.calories.carb,
      digest: ingredient.calories.digest,
      desc: ingredient.desc,
      author: ingredient.link,
      id: ingredient._id,
      cooking: ingredient.cooking,
      purified: ingredient.purified,
      packing: ingredient.packing ,
      mainMeasure: ingredient.mainMeasure,
      vitamins: ingredient.vitamins,
      minerals: ingredient.minerals,
      brands: ingredient.brands,
      season: ingredient.season,
      nameRu: ingredient.nameRu ,
      nameEn: ingredient.nameEn,
      ...measure,
    }) 
    setFields(ingredient.fields === null ? [] : ingredient.fields)
   
  }, [ingredient]);
 
  function measureRatio(measure) {
    const arr ={}
    if(measure !== null) {
    measure.map((i)=> {
      arr[i.nameEn] = i.ratio
      }) 
    }
    return arr
  }

  function hanleChangeRatio(obj, array) { 
    let arr=[]
    Object.entries(obj).map(([key, value]) => {
      array.map((item) => {
        if( key=== item.nameEn && value!== ''){
          arr.push({
            nameRu: item.nameRu,
            nameEn: item.nameEn,
            ratio: value
          }) 
        }
      })
    })
    return arr
  }

  function handleDelete() {
    dispatch(deleteIngredients(id))
   }

   function handleEdit() { 
    props.editIngredient(ingredient)
   }
 
   function handleSave(event) {
    event.preventDefault(); 
    const measures = hanleChangeRatio(values, lib.measure)
    console.log(measures, values)
    dispatch(editIngredients({values, measures: measures, fields: fields, vitamins: values.vitamins, minerals: values.minerals,choiceIngredient: values.brands, id: ingredient._id }))
  }
 
  function handleCopy(event) {
    event.preventDefault(); 
    props.copyIngredient(ingredient)
  }

  function  handleExcept(item, values) { 
    if (values && !fields.includes(item._id)) {
      setFields([...fields, item._id])
    } else {
      setFields(fields.filter(field => field !== item._id))
    }
  }
  
  return (
    <tr className={`table__tr item__link ${ingredient.nameEn && 'table__ok'}`} >
      <td className='table__td'>{ingredient.name} {ingredient.trait}</td>
      <td className='table__td'>{ingredient.category}</td>
      {/*<td className='table__td'>{ingredient._id}</td>*/}
       {/*<td className='table__td_price'> 
        <MeasureData 
          type='number' 
          types='table'
          value={values.purified} 
          name='purified' 
          placeholder= 'холодная'
          id='purified' 
          nameEn = 'purified' 
          nameRu = 'холодная'
          onChange={handleChange} 
        />
        <MeasureData 
          type='number' 
          types='table'
          value={values.cooking} 
          name='cooking' 
          placeholder= 'тепловая'
          id='cooking' 
          nameEn = 'cooking' 
          nameRu = 'тепловая'
          onChange={handleChange} 
        />
        <MeasureData 
          type='number' 
          types='table'
          value={values.frying} 
          name='frying' 
          placeholder= 'при жарке'
          id='frying' 
          nameEn = 'frying' 
          nameRu = 'при жарке'
          onChange={handleChange} 
        />
        </td>
     <td className='table__td table__td_price'>{lib.measure !== undefined &&
        lib.measure.map((i, index)=> (
        <MeasureData item={i} key={index} 
        type='number' 
        types='table'
        value={values[i.nameEn]}  
        placeholder= {i.nameRu}
        onChange={handleChange} 
        name={i.nameEn}
        id={i.nameRu}
        nameEn = {i.nameEn}
        nameRu = {i.nameRu} />
      ))}</td>
     <td className='table__td_price'> 
        <PriceBlock 
          price={values.price} 
          onChange={handleChange} 
          weightprice={values.weightprice}
          mainMeasure={values.measureprice}
          disabled ={true}
        />
      </td> 
      <td className='table__td_price'> 
        <MeasureData 
          type='number' 
          types='table'
          value={values.calories} 
          name='calories' 
          placeholder= 'Калории'
          id='calories' 
          nameEn = 'calories' 
          nameRu = 'Калории'
          onChange={handleChange} 
        />
        <MeasureData 
          type='number' 
          types='table'
          value={values.protein} 
          name='protein' 
          placeholder= 'Белки'
          id='protein' 
          nameEn = 'protein' 
          nameRu = 'Белки'
          onChange={handleChange} 
        />
        <MeasureData 
          type='number' 
          types='table'
          value={values.fats} 
          name='fats' 
          placeholder= 'Жиры'
          id='fats' 
          nameEn = 'fats' 
          nameRu = 'Жиры'
          onChange={handleChange} 
        />
        <MeasureData 
          type='number' 
          types='table'
          value={values.carb} 
          name='carb' 
          placeholder= 'Углеводы'
          id='carb' 
          nameEn = 'carb' 
          nameRu = 'Углеводы'
          onChange={handleChange} 
        /> 
        </td> */}
        <td className='table__td table__td_price'>{lib.measure !== undefined &&
        lib.measure.map((i, index)=> (
        <MeasureData item={i} key={index} 
        type='number' 
        types='table'
        value={values[i.nameEn]}  
        placeholder= {i.nameRu}
        onChange={handleChange} 
        name={i.nameEn}
        id={i.nameRu}
        nameEn = {i.nameEn}
        nameRu = {i.nameRu} />
      ))}</td>
       <td className='table__td_price'> 
       <div className=' '>
        {lib.except !== undefined && lib.except.length > 0 &&
          lib.except.map((item)=> (
            <Except 
              item={item} 
              key={item._id} 
              fields={fields}
              onClick={handleExcept}/>
            ))}
          </div> 
      </td>
         <td className='table__td_price'> 
        <PriceBlock 
          price={values.price} 
          onChange={handleChange} 
          weightprice={values.weightprice}
          mainMeasure={values.measureprice}
          disabled ={true}
        />
      </td> 
      <td className='table__td table__td_price'>
        {/*<button className='gg-delete item__link' onClick={handleDelete}></button>*/}
        <button className='gg-edit item__link' onClick={handleEdit}></button>
        <button className='gg-save item__link' onClick={handleSave}></button>
        <button className='gg-copy item__link' onClick={handleCopy}></button>
      </td>
      
    </tr>
  )
   
}