import React from 'react';
import { SelectionCategory, SearchItem, Except, InputItem,InputMeasure, AddButton, MainMeasure } from '../../atoms';
import { PriceBlock } from '../../molecules';
 
export function FormIngredient(props) {
  const values= props.values 
  return (
    <form className='form' onSubmit={props.onSubmit} autoComplete="off">
        <div className='add-recipe__level'>
          <fieldset className='form__fieldset'> 
            <InputItem 
              type='text' 
              value={values.name}
              onChange={props.onChange}
              placeholder= 'Название ингредиента'
              name='name' 
              id='name' 
              nameError={props.nameError}
            />
            <InputItem 
              type='text' 
              value={values.trait}
              onChange={props.onChange}
              placeholder= 'Вариация ингредиента'
              name='trait' 
              id='trait' 
              nameError={props.nameError}
            />
             <InputItem 
              type='text' 
              value={values.nameEn}
              onChange={props.onChange}
              placeholder= 'Название ингредиента на английском'
              name='nameEn' 
              id='nameEn' 
            />
            <label className='form__label' htmlFor='category'>
              <select 
                value={values.category || ''}
                onChange={props.onChange} 
                className='form__input' 
                name='category' 
                id='category'>
                <option value="none" name="none">Категория ингредиента</option>
                  {props.lib.catIn !== undefined && props.lib.catIn.length > 0 &&
                props.lib.catIn.map((item, index)=> (
                    <SelectionCategory item={item} key={index}/>
                  ))}
              </select>
            </label>
          </fieldset>
          <fieldset className='form__fieldset '> 
            <SearchItem // Автодобавление бренда
              type='search'
              editCardData={props.editCardData}
              handleClickPacking={props.handleClickPacking}
              filtrMassiv={props.filtrMassiv} 
              handleChangeInput={props.handleChangeInput}
              placeholder='Введите названия брендов'
              name='search-brands'
              id='search-brands'
              value={props.ingredient}
              handleAutoText={props.handleAutoText}
              hidden={props.hidden}
              choiceIngredient={props.choiceIngredient}
              handleDelete={props.handleDelete}
              ingError={props.ingError}
              />
              <div className='form__block_row form__block_row_wrap'>
              {props.lib.except !== undefined && props.lib.except.length > 0 &&
                props.lib.except.map((item)=> (
                <Except 
                item={item} 
                key={item._id} 
                fields={props.fields}
                onClick={props.handleExcept}/>
              ))}
             </div>  
          </fieldset>
          <fieldset className='form__fieldset form__fieldset_column'> 
          <h2 className='form__title'>Потери при обработке продукта</h2>
          <div className='form__block_row form__block_row_nowrap'>
          <InputItem 
              type='number' 
              value={values.purified}
              onChange={props.onChange}
              placeholder= '% холодная'
              name='purified' 
              id='purified' 
            />
          <InputItem 
              type='number' 
              value={values.cooking}
              onChange={props.onChange}
              placeholder= '% тепловая'
              name='cooking' 
              id='cooking' 
            />
            <InputItem 
              type='number' 
              value={values.frying}
              onChange={props.onChange}
              placeholder= '% при жарке'
              name='frying' 
              id='frying' 
            /> 
            <label className='form__label' htmlFor='season'>
              <select 
                value={values.season || ''}
                onChange={props.onChange} 
                className='form__input' 
                name='season' 
                id='season'>
                <option value="none" name="none">Сезон</option>
                  { 
                props.seasons.map((item, index)=> (
                    <SelectionCategory item={item} key={index}/>
                  ))}
              </select>
            </label>
            <MainMeasure 
              value={values.mainMeasure}
              onChange={props.onChange} 
              placeholder='расчетная мера'
            />
            </div>
          </fieldset>
          <fieldset className='form__fieldset form__fieldset_column'> 
          <h2 className='form__title'>Цена за вес продукта</h2>
          <div className='form__block_row form__block_row_nowrap'>
            <PriceBlock 
              price={values.price} 
              lib={props.lib}
              mainMeasure={values.mainMeasure}
              handleChangeSelect={props.handleChangeSelect}
              onChange={props.onChange} 
              weightprice={values.weightprice}
              measureprice={values.measureprice}
            />
            </div>
          </fieldset>
          <fieldset className='form__fieldset form__fieldset_column'> 
            <h2 className='form__title'>Энергетическая ценность на 100г</h2>
            <div className='form__block_row form__block_row_nowrap'>
              <InputItem 
                type='number' 
                value={values.calories}
                onChange={props.onChange}
                placeholder= 'Калории'
                name='calories' 
                id='calories' 
              />
             <InputItem 
                type='number' 
                value={values.protein}
                onChange={props.onChange}
                placeholder= 'Белки'
                name='protein' 
                id='protein'
              />
               <InputItem 
                type='number' 
                value={values.fats}
                onChange={props.onChange}
                placeholder= 'Жиры'
                name='fats' 
                id='fats'
              />
               <InputItem 
                type='number' 
                value={values.carb}
                onChange={props.onChange}
                placeholder= 'Углеводы'
                name='carb' 
                id='carb'
              />
              <InputItem 
                type='number' 
                value={values.digest}
                onChange={props.onChange}
                placeholder= 'Время переваривания'
                name='digest' 
                id='digest'
              />
            </div>
          </fieldset>
          <fieldset className='form__fieldset form__fieldset_column'>
            <div className='form__block'>
              <h2 className='form__title'>Мера веса</h2>
              <ul className='form__list'>
              {props.lib.measure !== undefined &&
                props.lib.measure.length > 0 &&
                props.lib.measure.map((item, index)=> (
                <InputMeasure 
                  type='number' 
                  types='measure'
                  value={values[item.nameEn] !== undefined ? values[item.nameEn] : ''}
                  key={index}
                  onChange={props.onChange}
                  placeholder= 'вес в граммах'
                  name={item.nameEn}
                  id={item.nameEn}
                  nameru={item.nameRu} 
                />
              ))}
               </ul>
            </div>
            <div className='form__block'>
              <h2 className='form__title'>Витамины</h2>
              <ul className='form__list'>
                {props.lib.vitamins !== undefined &&
                props.lib.vitamins.length > 0 &&
                   props.lib.vitamins.map((item, index)=> (
                  <InputMeasure
                    item={item}
                    key={index}
                    value={props.vitam[item.nameEn] !== undefined ? props.vitam[item.nameEn] : ''}
                    type='number'
                    types='measure'
                    placeholder= 'Введите значение'
                    onChange={props.handleChangeMinerals}
                    name={item.nameEn}
                    id={item.nameEn}
                    nameru={item.nameEn}
                  />
                ))}
              </ul>
              
            </div>
          </fieldset>
        </div>  
        <AddButton 
          type='submit' 
          title = {props.title}
          submitDisabled={props.submitDisabled} 
        />
      </form> 
  )  
}
