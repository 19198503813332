import { FormIngredient, } from '../index';


export function EditForm(props) {
  return (
    <FormIngredient 
      lib={props.lib}
      onClose={props.onClose}
      mainMeasure={props.mainMeasure}
      filtrMassiv={props.filtrMassiv}
      values={props.values}
      vitam={props.vitam}
      fields={props.fields}
      seasons = {props.seasons}
      handleExcept={props.handleExcept}
      handleChangeMinerals={props.handleChangeMinerals}
      handleChangeSelect={props.handleChangeSelect}
      handleClickPacking={props.handleClickPacking}
      activeCooking={props.activeCooking}
      cooking={props.cooking}
      hidden={props.hidden}
      handleDelete={props.handleDelete}
      handleAutoText={props.handleAutoText}
      choiceIngredient={props.choiceIngredient}
      onSubmit={props.onSubmit}
      isOpen={props.isOpen}
      onChange={props.onChange}
      ratioMeasure={props.ratioMeasure}
      title={props.title}
      price={props.price}
      measureprice = {props.measureprice}
      weightprice = {props.weightprice}
      category={props.category}
      calories={props.calories}
      protein={props.protein}
      fats={props.fats}
      carb={props.carb}
      digest={props.digest}
      editCardData={props.editCardData}
      measure={props.measure}
      name={props.name}
      nameError={props.nameError}
      submitDisabled={props.submitDisabled}
      handleChangeInput={props.handleChangeInput}
      ingredient={props.ingredient}
   />
  )
}
  