//Кнопки выбора типов питания

import React from 'react';

export function Except (props) {
  const item = props.item 
  const fields = props.fields
  const except = fields.includes(item._id)
  const [values, setValues] = React.useState(except)
  
  React.useEffect(() => { 
    setValues(except)
  }, [except]); 

  function handleClick () { 
    setValues(!values)
    props.onClick(item, !values)
  }
  
  return (
    <li onClick={handleClick} className={`button__type item__link ${values && ('button__type_active')}`}>{item.title}</li>
  )
}