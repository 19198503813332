import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { EditVitIngredients, deleteVitamins, getUpdateFieldIngredients, addNewIngredients} from '../../../store/api';

  export const Test = () => {
  const [csvData, setCsvData] = React.useState([]);
  const [csvNew, setCsvNew] = React.useState([]);
  const [vitamins, setVitamins] = React.useState([]);
  const dispatch = useDispatch();
  const { ingredientsField, statusField } = useSelector(state => state.ingredients)
  const { lib, status } = useSelector(state => state.lib)
  React.useEffect(()=> {
    if(status === 'resolved' ) {
      setVitamins(hanleChangeRatio(csvData))
    }

  }, [csvData, status])

  //Загрузка Витаминов в базу
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
  
    reader.onload = (e) => {
      const data = e.target.result;
      const lines = data.trim().split('\n');
      const headers = lines[0].split(';');
      const csvObjects = [];
  
      for (let i = 1; i < lines.length; i++) {
        const values = lines[i].split(';');
        const obj = {};
        for (let j = 0; j < headers.length; j++) { 
         obj[headers[j]] =  values[j] === '' ? 0 : isNaN(parseFloat(values[j].replace(',', '.'))) ? values[j].replace(',', '.') : parseFloat(values[j].replace(',', '.'))
        } 
        csvObjects.push(obj);
      }
  
      setCsvData(csvObjects);
    };
  
    reader.readAsText(file);
  };

  const handleFileUp = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
  
    reader.onload = (e) => {
      const data = e.target.result;
      const lines = data.trim().split('\n');
      const headers = lines[0].split(';');
      const csvObjects = [];
  
      for (let i = 1; i < lines.length; i++) {
        const values = lines[i].split(';');
        const obj = {};
        for (let j = 0; j < headers.length; j++) { 
         obj[headers[j]] =  values[j] === '' ? 0 : isNaN(parseFloat(values[j].replace(',', '.'))) ? values[j].replace(',', '.') : parseFloat(values[j].replace(',', '.'))
        } 
        csvObjects.push(obj);
      }
  
      setCsvNew(csvObjects);
    };
  
    reader.readAsText(file);
  };

 
  /*function convertToCSV(data) {
    console.log(data)
    const headers = Object.keys(data[0]).join(',') + '\n';
    const rows = data.map(obj => Object.values(obj).join()).join('\n');
    console.log(headers, rows)
    return headers + rows;
  }*/

  function convertToCSV(data) { 
    let headers = getAllKeys(data[4]).join(',') + '\n';; 
    const rows = data.map(obj => {
        let row = Object.values(obj).map(value => {
       
            if (typeof value === 'object' && value !== null) {
                return  Object.values(value);
            } else {
                return value;
            }
        });
        const newrArr = row.flat() 
          return newrArr.join(',');
         
    }).join('\n');  
    return headers + rows;
}
  
function getAllKeys(obj) {
  if (!obj || typeof obj !== 'object') {
    return [];
  }
 
  let keys = Object.keys(obj);
  console.log(obj, Object.keys(obj))
  keys.forEach(key => { 
    if (typeof obj[key] === 'object') {
      keys = keys.concat(getAllKeys(obj[key]));
    }
  });
  return keys;
}

function download() {
  dispatch(getUpdateFieldIngredients()) 
    downloadCSV() 
 
}
     function downloadCSV() { 
      if(statusField === 'resolved') {
      const csvDatas = convertToCSV(ingredientsField);
      const blob = new Blob([csvDatas], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'data.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      }
  } 

 function hanleChangeRatio(vitamins) {
  const transformedArray = vitamins.map(updatedValue => {
    return {
    name: updatedValue.nameRu,
    calories:  updatedValue.calories, 
    fats: updatedValue.fats,
    carb: updatedValue.carb,
    protein: updatedValue.protein,
    kj: updatedValue.kj,
    /*vitamins: Object.keys(obj).filter(vitamin=> vitamin !== 'nameRu' && vitamin !== 'category' && vitamin !== 'calories' && vitamin !== 'fats' && vitamin !== 'carb' && vitamin !== 'protein'  && vitamin !== 'kj').map(k => {
      return { nameEn: k, ratio: obj[k] };
    })*/
    vitamins: {
      water: updatedValue.water,
        sugar: updatedValue.sugar,
        fiber: updatedValue.fiber,
        Ca: updatedValue.Ca,
        Fe: updatedValue.Fe,
        Mg: updatedValue.Mg,
        P: updatedValue.P,
        K: updatedValue.K,
        Na: updatedValue.Na,
        Zn: updatedValue.Zn,
        Cu: updatedValue.Cu,
        Mn: updatedValue.Mn,
        Se: updatedValue.Se,
        F: updatedValue.F,
        VitA: updatedValue.VitA,
        VitB1: updatedValue.VitB1,
        VitB2: updatedValue.VitB2,
        VitB3: updatedValue.VitB3,
        VitВ4: updatedValue.VitВ4,
        VitB5: updatedValue.VitB5,
        VitB6: updatedValue.VitB6,
        VitB9: updatedValue.VitB9,
        VitВ12: updatedValue.VitВ12,
        VitC: updatedValue.VitC,
        VitD: updatedValue.VitD,
        VitE: updatedValue.VitE,
        VitK: updatedValue.VitK,
        betaine: updatedValue.betaine,
        Saturated: updatedValue.Saturated,
        Monounsaturated: updatedValue.Monounsaturated,
        Polyunsaturated: updatedValue.Polyunsaturated,
        cholesterol: updatedValue.cholesterol,
        tryptophan: updatedValue.tryptophan,
        threonine: updatedValue.threonine,
        isoleucine: updatedValue.isoleucine,
        leucine: updatedValue.leucine,
        lysine: updatedValue.lysine,
        methionine: updatedValue.methionine,
        cystine: updatedValue.cystine,
        phenylalanine: updatedValue.phenylalanine,
        tyrosine: updatedValue.tyrosine,
        valin: updatedValue.valin,
        arginine: updatedValue.arginine,
        histidine: updatedValue.histidine,
        alanin: updatedValue.alanin,
        aspartic: updatedValue.aspartic,
        glutamic: updatedValue.glutamic,
        glycine: updatedValue.glycine,
        proline: updatedValue.proline,
        serin: updatedValue.serin,
        alcohol: updatedValue.alcohol,
        caffeine: updatedValue.caffeine,
        theobromine: updatedValue.theobromine,
        'Omega-3': updatedValue.Omega-3,
        'Omega-6': updatedValue.Omega-6,
    }
    };
    });
    
    return transformedArray
  
}

 function handleSubmit() {
   dispatch(EditVitIngredients(vitamins))
 }

 function handleSubmitNew() {
  dispatch(addNewIngredients(csvNew))
}

 function deleteVit() {
  dispatch(deleteVitamins())
 }
  return (
    <section className='file'>
    <div>
      <label>Загрузить витамины</label> 
      <input type="file" accept=".csv" onChange={handleFileUpload} />
      <button onClick={handleSubmit}>Обновить</button>
      {csvData.length > 0 && (
        <div>
          <h2>Parsed CSV Data</h2>
          <pre>{JSON.stringify(csvData, null, 2)}</pre>
        </div>
      )}
    </div>

  <div> 
  <label>Выгрузить ингредиенты Csv</label> 
    <button type='button' onClick={download}>Скачать</button>
  </div>

  <div> 
  <label>Обнулить свойство витаминов  вбазе у ингредиентов (тк не используется)</label> 
    <button type='button' onClick={deleteVit}>Удалить витамины</button>
  </div>
  <div>
      <label>Загрузить Ингредиенты Новые</label> 
      <input type="file" accept=".csv" onChange={handleFileUp} />
      <button onClick={handleSubmitNew}>Обновить</button>
      {csvNew.length > 0 && (
        <div>
          <h2>Parsed CSV Data</h2>
          <pre>{JSON.stringify(csvNew, null, 2)}</pre>
        </div>
      )}
    </div>
</section>
  );
};
   