//Разметка Добавления витаминов в базу

import React from 'react';
import { AddButton, InputMeasure, SelectionCategory} from '../'
import { categoryVitamins } from '../../../constants/constants';

export function VitaminCard (props) {
  const values= props.values
 
  return (
    <form className="form" onSubmit={props.onSubmit}> 
    {props.arr.map((item, index)=>(
      <InputMeasure 
        item={item}
        type={item.type}
        key={index}
        value={values[item.nameEn] !== undefined ? values[item.nameEn] : ''}
        name={item.nameEn}
        id={item.nameEn}
        nameru={item.nameRu}
        placeholder='введите значение'
        onChange={props.handleChange}
        handleClick={props.handleClick}
      />
    ))}
    {props.type === 'vitamins' &&
      <label className='form__label' htmlFor='category'>
      <select 
        value={values.category || ''}
        onChange={props.handleChange} 
        className='form__input' 
        name='category' 
        id='category'>
        <option value="none" name="none">Категория витамина</option> 
        {categoryVitamins.map((item, index)=> (
            <SelectionCategory item={item} key={index}/>
          ))}
      </select>
    </label>
    }
      <AddButton 
        type='submit'
        title={props.title}
      />
    </form>
  )
}
 

